import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../css/blog.css";

function BlogTemplate({ pageContext }) {
  const post = pageContext;

  /// const { title, author, date } = post.frontmatter;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Consultant's Couch | {post.title}</title>
        <script
          type="text/javascript"
          async=""
          src="https://www.google-analytics.com/analytics.js"
        ></script>
        <script
          async=""
          src="//www.googletagmanager.com/gtm.js?id=GTM-M346X68"
        ></script>
        <meta name="description" content={post.description} />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="blog post"></meta>
        <meta property="og:title" content={post.title}></meta>
        {post.category.map((tag) => {
          return <meta property="article:tag" content={tag.title}></meta>;
        })}
        <meta property="article:published_time" content={post.date}></meta>
        <meta property="og:description" content={post.description}></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <meta name="twitter:card" content={post.image}></meta>
        <meta name="twitter:description" content={post.description}></meta>
        <meta
          name="twitter:title"
          content={"The Consultant's Couch | " + post.title}
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <div className="PostPage max-w-screen-xl mx-auto">
        <div class="flex justify-center">
          <div class="mt-20 inline-flex items-center text-center px-6 py-2 rounded-md text-base font-medium bg-yellow-100 text-yellow-800 hover:opacity-80">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7 15h13v1h-13v-1zm4-4v3h5v-3h-5zm-1 0h-3v1h3v-1zm-3 3h3v-1h-3v1zm13-1h-3v1h3v-1zm-6.951-6.573v-.396h-1.215v1.941h1.255v-.396h-.78v-.406h.698v-.393h-.698v-.35h.74zm1.396.261l.238 1.284h.5l.501-1.941h-.482l-.249 1.32-.238-1.32h-.492l-.27 1.345-.24-1.345h-.505l.46 1.941h.506l.271-1.284zm1.901.916c-.149 0-.324-.043-.466-.116l-.024-.013-.098.398.015.008c.102.058.318.119.547.119.581 0 .788-.328.788-.61 0-.272-.161-.458-.507-.586-.254-.096-.338-.145-.338-.247 0-.098.1-.161.254-.161.136 0 .266.03.388.088l.023.011.107-.39-.015-.007c-.145-.065-.311-.098-.495-.098-.442 0-.739.239-.739.593 0 .262.181.458.535.581.227.081.304.144.304.247 0 .117-.102.183-.279.183zm-5.325.368h.485v-1.941h-.438v1.189l-.641-1.189h-.535v1.941h.438v-1.327l.691 1.327zm8.979 1.028h-13v1h13v-1zm0 2h-3v1h3v-1zm-17-9v17.199c0 .771-1 .771-1 0v-15.199h-2v15.98c0 1.115.905 2.02 2.02 2.02h19.958c1.117 0 2.022-.904 2.022-2.02v-17.98h-21zm19 16h-17v-14h17v14z" />
            </svg>

            <a href="/" class="inline-flex items-center">
              The Consultant's Couch
            </a>
          </div>
        </div>

        <main className="mt-8">
          <div className="mb-4 md:mb-0 w-full mx-auto relative">
            <div className="px-4 lg:px-0">
              <h2 className="text-3xl lg:text-5xl text-center font-bold text-blue-800 leading-tight">
                {post.title}
              </h2>
              <div>
                <div className="text-center mt-4 mb-6 lg:flex lg:justify-center ">
                  <div className="text-blue-900 mt-3 text-md font-medium inline-block">
                    <time datetime="2020-03-16">{"Posted " + post.date}</time>
                  </div>

                  <div>
                    <a className="hidden mt-3 lg:ml-8 inline-block lg:border-l lg:pl-9">
                      <span className=" inline-flex items-center py-0.5 text-md font-medium leading-5 text-green-600">
                        {post.category.map((tag) => {
                          return (
                            <p className="-mt-10 leading-3 inline-block pr-2 ">
                              {tag + ","}
                            </p>
                          );
                        })}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <img
              src={post.image}
              className="w-full h-72 object-cover lg:rounded"
            />
          </div>

          <div className="w-full">
            <div className="px-4 lg:px-0 mt-12 text-gray-700 text-lg leading-relaxed w-full ">
              <div
                className="blogStyling pb-6"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>{" "}
          </div>
          <div class="flex justify-center">
            <div class="mt-5 mb-14 inline-flex items-center text-center px-10 py-4 rounded-md text-xl font-medium bg-cyan-100 text-cyan-800 hover:opacity-80">
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M7 15h13v1h-13v-1zm4-4v3h5v-3h-5zm-1 0h-3v1h3v-1zm-3 3h3v-1h-3v1zm13-1h-3v1h3v-1zm-6.951-6.573v-.396h-1.215v1.941h1.255v-.396h-.78v-.406h.698v-.393h-.698v-.35h.74zm1.396.261l.238 1.284h.5l.501-1.941h-.482l-.249 1.32-.238-1.32h-.492l-.27 1.345-.24-1.345h-.505l.46 1.941h.506l.271-1.284zm1.901.916c-.149 0-.324-.043-.466-.116l-.024-.013-.098.398.015.008c.102.058.318.119.547.119.581 0 .788-.328.788-.61 0-.272-.161-.458-.507-.586-.254-.096-.338-.145-.338-.247 0-.098.1-.161.254-.161.136 0 .266.03.388.088l.023.011.107-.39-.015-.007c-.145-.065-.311-.098-.495-.098-.442 0-.739.239-.739.593 0 .262.181.458.535.581.227.081.304.144.304.247 0 .117-.102.183-.279.183zm-5.325.368h.485v-1.941h-.438v1.189l-.641-1.189h-.535v1.941h.438v-1.327l.691 1.327zm8.979 1.028h-13v1h13v-1zm0 2h-3v1h3v-1zm-17-9v17.199c0 .771-1 .771-1 0v-15.199h-2v15.98c0 1.115.905 2.02 2.02 2.02h19.958c1.117 0 2.022-.904 2.022-2.02v-17.98h-21zm19 16h-17v-14h17v14z" />
              </svg>

              <a href="/blog/" class="inline-flex items-center">
                View All Posts
              </a>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
}

export default BlogTemplate;
